.App {
  text-align: center;
}

img{
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

h2{
  font-size: 4rem;
}

h3{
  font-size: 2.5rem;
}